<template>
  <v-dialog
    :value="msgDiag"
    width="300"
  >
    <v-card class="elevation-12">
      <v-card-text>
        {{ msgDiagText }}
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          @click="$store.commit('msgDiagDismiss')"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: mapState(['msgDiag', 'msgDiagText']),
};
</script>
