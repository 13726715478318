<template>
  <v-app id="inspire">
    <v-content>
      <LoginCard
        login-type="admin"
        @loginSuccess="loginSuccess"
      />
    </v-content>

    <v-footer
      color="primary"
      class="white--text"
      app
    >
      <template v-if="$vuetify.breakpoint.smAndUp">
        <a
          href="https://mandomedio.com/outplacement-personas/"
          style="color: white; margin: 0 0.5% 0 0.5%"
          target="_blank"
        >
          Asesoría en Empleabilidad
        </a>
        <router-link
          :to="{name: 'privacy-policy'}"
          target="_blank"
          style="color: white"
        >
          Política de Privacidad
        </router-link>
      </template>
      <template v-else>
        <a
          href="https://mandomedio.com/outplacement-personas/"
          style="color: white; margin: 0 1% 0 1%"
          target="_blank"
        >
          Empleabilidad
        </a>
        <router-link
          :to="{name: 'privacy-policy'}"
          target="_blank"
          style="color: white"
        >
          Privacidad
        </router-link>
      </template>
      <v-spacer />
      <a
        href="https://mandomedio.com"
        style="color: white; margin: 0 1% 0 1%"
        target="_blank"
      >
        &copy; Mandomedio 2019
      </a>
    </v-footer>
    <LoadingComponent />
    <GenericMessageDialog />
    <GenericSnackbar />
  </v-app>
</template>

<script>
import LoginCard from '@/components/forms/LoginCard.vue';
import GenericMessageDialog from '@/components/misc/GenericMessageDialog.vue';
import LoadingComponent from '@/components/misc/LoadingComponent.vue';
import GenericSnackbar from '@/components/misc/GenericSnackbar.vue';
import { JwtIsExpired } from '@/utils/jwt';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';

export default {
  name: 'AdminLogin',
  components: {
    LoginCard,
    GenericMessageDialog,
    LoadingComponent,
    GenericSnackbar,
  },
  mixins: [
    JWTConfigMixin,
  ],
  computed: {
    userIsLoggedIn() {
      return !JwtIsExpired(this.$store.state.jwt);
    },
  },
  methods: {
    loginSuccess() {
      if (this.userIsLoggedIn) {
        if (JSON.parse(window.localStorage.vuex).isAdmin) {
          this.$router.push({ name: 'admin:home' });
        } else {
          // alert('no tiene permiso para entrar');
          this.logout();
        }
      }
    },
  },
};
</script>
