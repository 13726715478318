<template>
  <v-snackbar
    :value="adminSnackbar"
    :bottom="true"
    :right="true"
    :timeout="timeout"
    :multi-line="true"
  >
    {{ adminSnackbarText }}
    <v-btn
      color="primary"
      flat
      @click="closeSnackbar"
    >
      Cerrar
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    timeout: {
      type: Number,
      default: 0,
    },
  },
  computed: mapState(['adminSnackbar', 'adminSnackbarText']),
  methods: {
    closeSnackbar() {
      this.$store.commit('adminSnackbarDown');
    },
  },
};
</script>
